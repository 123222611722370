import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      totalRecords: 0,
      exportDisable: true,
      summaryData1: [],
      summaryData2: [],
      disableAll: true,
      disableSpecific: true,
      selectedBusiness: "",
      businessData: [],
      selectedWarehouse: "",
      warehouseData: [],
      includePartNo: false,
      dateSelected: false,
      runDisabled: true,
      partClassSearchValid: false,
      excelName: "",
      showPartNum: false,
      hideSpecific: false,
      hideAll: false,
      partSearchKey: "",
      showPartNumDetails: false,
      partResultList: [],
      showPartDetails: false,
      showBusiness: false,
      showWarehouse: false,
      showIncludePartNo: false,
      export50kData: false,
      exportDialog: false,
      showPartType: false,
      showData: false,
      showPartNoData: false,
      debouncedGetPartList: [],
      urlString: "",
      apiObj: {
        pass: 0,
        user_id: 0,
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        ware_id: 0,
        group_by_date: false,
        lcsdate: "",
        lcedate: "",
      },
      headerSummary1: [
        { text: "Date", align: "start", value: "Date", class: "primary customwhite--text" },
        { text: "Receipts", value: "Receipts", class: "primary customwhite--text" },
        { text: "WIP To Scrap", value: "WIP_To_Scrap", class: "primary customwhite--text" },
        { text: "WIP To FGI", value: "WIP_To_FGI", class: "primary customwhite--text" },
        { text: "Shipments", value: "Shipments", class: "primary customwhite--text" },
      ],
      json_fields1: {
        Date: "Date",
        Receipts: "Receipts",
        "WIP To Scrap": "WIP_To_Scrap",
        "WIP To FGI": "WIP_To_FGI",
        Shipments: "Shipments",
      },
      headerSummary2: [
        { text: "Date", align: "start", value: "Date", class: "primary customwhite--text" },
        { text: "Part#", value: "Part", class: "primary customwhite--text" },
        { text: "Receiving - TBE", value: "Receiving_TBE", class: "primary customwhite--text" },
        { text: "WIP To Scrap", value: "WIP_To_Scrap", class: "primary customwhite--text" },
        { text: "WIP To FGI", value: "WIP_To_FGI", class: "primary customwhite--text" },
        { text: "Shipments - FGI", value: "Shipments_FGI", class: "primary customwhite--text" },
      ],
      json_fields2: {
        Date: "Date",
        "Part#": "Part",
        "Receiving - TBE": "Receiving_TBE",
        "WIP To Scrap": "WIP_To_Scrap",
        "WIP To FGI": "WIP_To_FGI",
        "Shipments - FGI": "Shipments_FGI",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.debouncedGetPartList = Utility.debounce(this.getPartList, 500);
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
    total() {
      const sums = {
        Receipts: 0,
        WIP_To_Scrap: 0,
        WIP_To_FGI: 0,
        Shipments: 0,
      };
      this.summaryData1.forEach(({ Receipts, WIP_To_Scrap, WIP_To_FGI, Shipments }) => {
        sums.Receipts += Receipts;
        sums.WIP_To_Scrap += WIP_To_Scrap;
        sums.WIP_To_FGI += WIP_To_FGI;
        sums.Shipments += Shipments;
      });
      return sums;
    },
    total1() {
      const sums = {
        Receiving_TBE: 0,
        WIP_To_Scrap: 0,
        WIP_To_FGI: 0,
        Shipments_FGI: 0,
      };
      this.summaryData2.forEach(({ Receiving_TBE, WIP_To_Scrap, WIP_To_FGI, Shipments_FGI }) => {
        sums.Receiving_TBE += Receiving_TBE;
        sums.WIP_To_Scrap += WIP_To_Scrap;
        sums.WIP_To_FGI += WIP_To_FGI;
        sums.Shipments_FGI += Shipments_FGI;
      });
      return sums;
    },
    processedData() {
      const data = this.summaryData2;
      if (!data.length) return [];
      const processed = [];
      let currentDate = null;
      let currentRowspan = 0;
      data.forEach((row) => {
        if (row.Date !== currentDate) {
          currentDate = row.Date;
          currentRowspan = data.filter((d) => d.Date === currentDate).length;
          row.rowspan = currentRowspan;
          row.skipDate = false;
        } else {
          row.skipDate = true;
        }
        processed.push(row);
      });
      return processed;
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.summaryData1 = [];
      this.summaryData2 = [];
      this.disableAll = true;
      this.disableSpecific = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.includePartNo = false;
      this.dateSelected = false;
      this.runDisabled = true;
      this.partClassSearchValid = false;
      this.excelName = "";
      this.showPartNum = false;
      this.hideSpecific = false;
      this.hideAll = false;
      this.partSearchKey = "";
      this.showPartNumDetails = false;
      this.partResultList = [];
      this.showPartDetails = false;
      this.showBusiness = false;
      this.showWarehouse = false;
      this.showIncludePartNo = false;
      this.export50kData = false;
      this.exportDialog = false;
      this.showPartType = false;
      this.showData = false;
      this.showPartNoData = false;
      this.urlString = "";
      this.apiObj = {
        pass: 0,
        user_id: 0,
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        ware_id: 0,
        group_by_date: false,
        lcsdate: "",
        lcedate: "",
      };
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.apiObj.pass = 0;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      let showMessage = false;
      let projData = await reportService.processThroughputSummary("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    async onChangeProject() {
      this.apiObj.pass = 1;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.proj_id = parseInt(this.selectedProject);
      let showMessage = false;
      let busData = await reportService.processThroughputSummary("post", this.apiObj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.disableAll = false;
        this.disableSpecific = false;
        this.runDisabled = true;
        this.showPartNum = true;
        this.hideSpecific = false;
        this.hideAll = false;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = false;
        this.showBusiness = false;
        this.showWarehouse = false;
        this.showIncludePartNo = false;
        this.summaryData1 = [];
        this.summaryData2 = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
        this.clearPartShip();
      }
    },
    //All Part Clicked
    allPartNumber() {
      this.showBusiness = true;
      this.showPartType = false;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showIncludePartNo = false;
      this.showInventoryLoc = false;
      this.runDisabled = true;
      this.hideSpecific = true;
      this.showData = false;
      this.clearPartShip();
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if (this.partSearchKey?.length > 2) {
        this.debouncedGetPartList();
      }
    },
    async getPartList() {
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },
    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
    showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.partDetailsShow = false;
      this.showBusiness = true;
      this.showPartType = true;
      this.showParentClass = false;
      this.showPartClass = false;
      this.showWarehouse = false;
      this.showIncludePartNo = false;
      this.runDisabled = true;
      this.showData = false;
      this.hideAll = true;
    },
    //Business selected
    async businessSelected() {
      this.apiObj.pass = 2;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.bu_id = parseInt(this.selectedBusiness);
      let showMessage = false;
      let otherData = await reportService.processThroughputSummary("post", this.apiObj, showMessage);
      if (otherData.message !== "NA") {
        this.warehouseData = otherData;
        this.selectedWarehouse = "";
        this.includePartNo = false;
        this.runDisabled = true;
        this.partSearchKey = "";
        this.showPartNumDetails = false;
        this.partResultList = [];
        this.showPartDetails = true;
        this.showWarehouse = true;
        this.showIncludePartNo = false;
        this.showData = false;
      }
    },
    //Warehouse selected
    warehouseSelected() {
      this.runDisabled = false;
      this.showData = false;
      this.showIncludePartNo = true;
    },
    //Run Report
    async runReport() {
      this.apiObj.pass = 3;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.bu_id = parseInt(this.selectedBusiness);
      this.apiObj.ware_id = parseInt(this.selectedWarehouse);
      this.apiObj.part_num = this.partNum;
      this.apiObj.group_by_date = !this.includePartNo;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      if (this.includePartNo) {
        this.urlString = "/rt/process_throughput_summary_new_part";
      } else {
        this.urlString = "/rt/process_throughput_summary_new_date";
      }
      this.axios
        .post(this.urlString, this.apiObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = response.data.body;
            this.showPartNoData = this.includePartNo;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                Date: moment(obj.Date).format("MM/DD/YYYY"),
              };
            });
            if (this.showPartNoData) {
              this.summaryData2 = newArr;
            } else {
              this.summaryData1 = newArr;
            }
            this.totalRecords = responseData.length;
            this.exportDisable = false;
            this.showData = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.summaryData1 = [];
          this.summaryData2 = [];
          this.totalRecords = 0;
          this.exportDisable = true;
          this.showData = false;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        this.apiObj.pass = 3;
        this.apiObj.user_id = parseInt(this.userId);
        this.apiObj.bu_id = parseInt(this.selectedBusiness);
        this.apiObj.ware_id = parseInt(this.selectedWarehouse);
        this.apiObj.part_num = this.partNum;
        this.apiObj.group_by_date = !this.includePartNo;
        if (this.includePartNo) {
          this.urlString = "/rt/process_throughput_summary_new_part";
        } else {
          this.urlString = "/rt/process_throughput_summary_new_date";
        }
        const response = await this.axios.post(this.urlString, this.apiObj);
        let responseData = response.data.body;
        responseData.forEach((element) => {
          element.Date = Utility.convertESTToLocalOnlyDate(element.Date);
        });
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Process Throughput Summary");
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Style configuration
        const colors = {
          lightGrey: { type: "pattern", pattern: "solid", fgColor: { argb: "DBDDE9" } },
        };
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        if (this.includePartNo) {
          worksheet.addRow(["Date", "Part#", "Receiving TBE", "WIP To Scrap", "WIP To FGI", "Shipments FGI"]);
          // Apply styles to the header rows
          worksheet.getRow(1).eachCell((cell) => {
            cell.style = { ...headerStyle, fill: colors.grey };
            cell.border = borderStyle;
          });
          let tempReceivingTBE = 0;
          let tempWIPToScrap = 0;
          let tempWIPToFGI = 0;
          let tempShipments = 0;
          // Add your data rows
          responseData.forEach((item) => {
            tempWIPToScrap = item.WIP_To_Scrap + tempWIPToScrap;
            tempReceivingTBE = item.Receiving_TBE + tempReceivingTBE;
            tempWIPToFGI = item.WIP_To_FGI + tempWIPToFGI;
            tempShipments = item.Shipments_FGI + tempShipments;
            const row = worksheet.addRow([item.Date, item.Part, item.Receiving_TBE, item.WIP_To_Scrap, item.WIP_To_FGI, item.Shipments_FGI]);
            row.eachCell({ includeEmpty: true }, (cell) => {
              // Apply borders
              cell.border = borderStyle;
            });
          });
          const subTotalRow = worksheet.addRow(["Sub-Total", "", tempReceivingTBE, tempWIPToScrap, tempWIPToFGI, tempShipments]);
          if (responseData.length + 1) {
            subTotalRow.eachCell({ includeEmpty: true }, (cell) => {
              // Apply borders
              cell.style.fill = colors.lightGrey;
              cell.border = borderStyle;
            });
          }
          const totalRow = worksheet.addRow(["Total", "", 0, 0, 0, 0]);
          if (responseData.length + 2) {
            totalRow.eachCell({ includeEmpty: true }, (cell) => {
              // Apply borders
              cell.style.fill = colors.lightGrey;
              cell.border = borderStyle;
            });
          }
        } else {
          worksheet.addRow(["Date", "Receipts", "WIP To Scrap", "WIP To FGI", "Shipments"]);
          // Apply styles to the header rows
          worksheet.getRow(1).eachCell((cell) => {
            cell.style = { ...headerStyle, fill: colors.grey };
            cell.border = borderStyle;
          });
          let tempReceipts = 0;
          let tempWIPToScrap = 0;
          let tempWIPToFGI = 0;
          let tempShipments = 0;
          // Add your data rows
          responseData.forEach((item) => {
            tempWIPToScrap = item.WIP_To_Scrap + tempWIPToScrap;
            tempReceipts = item.Receipts + tempReceipts;
            tempWIPToFGI = item.WIP_To_FGI + tempWIPToFGI;
            tempShipments = item.Shipments + tempShipments;
            const row = worksheet.addRow([item.Date, item.Receipts, item["WIP_To_Scrap"], item["WIP_To_FGI"], item.Shipments]);
            row.eachCell({ includeEmpty: true }, (cell) => {
              // Apply borders
              cell.border = borderStyle;
            });
          });
          const row = worksheet.addRow(["Total", tempReceipts, tempWIPToScrap, tempWIPToFGI, tempShipments]);
          if (responseData.length + 1) {
            row.eachCell({ includeEmpty: true }, (cell) => {
              // Apply borders
              cell.style.fill = colors.lightGrey;
              cell.border = borderStyle;
            });
          }
        }
        // Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Process_Throughput_Summary"));
        this.export50kData = false;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
